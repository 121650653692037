.mapContainer {
  position: relative;
  display: flex;
  width: 100%;
  height: 100vh;
  }

.marker {
  background-image: url('../img/baseline_place_black_18dp.png');
  background-size: cover;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
}